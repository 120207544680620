import React, {useCallback, useEffect, useState} from 'react';
import Header from 'components/Header';
import TitleArea from '../common/TitleArea';
import {Link} from '@mui/material';
import DefaultAlert from '../alert/DefaultAlert';
import {localStorageUtil} from '../../common/utils';
import {fetchWrapper, history} from '../../_helpers';
import {SUCCESS_CODE} from '../../common/constants';

function PrivacyPolicy() {
    // const [open, setOpen] = useState(false);
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);

    const [golfClubPartners, setGolfClubPartners] = useState('');

    const partnersOpen = () => {
        history.navigate('/linked-golfclub');
    };

    const getGolfClubPartners = useCallback(() => {
        fetchWrapper.get(`/golfClub/partners`, null).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                setGolfClubPartners(response.data);
            }
        });
    }, []);

    useEffect(() => {
        getGolfClubPartners();
    }, [getGolfClubPartners]);

    return (
        <>
            <Header close={true} />
            <div id="contents">
                <div id="agreeContents" className="contentsArea">
                    <div className="inner">
                        <TitleArea pageTitle={'개인정보 처리방침'} />
                        <div className="agreeCon">
                            <section>
                                <p>
                                    (주)그린잇(이하 “회사”)은 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」 및
                                    관계 법령이 정한 바를 준수하여 적법하게 개인정보를 처리하고 안전하게 관리하고
                                    있습니다. 이에 「개인정보 보호법」 제30조에 따라 정보주체에게 개인정보 처리에 관한
                                    절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기
                                    위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
                                </p>
                            </section>
                            <section>
                                <h2>목차</h2>
                                <p>
                                    1. 개인정보의 처리 목적
                                    <br />
                                    2. 처리하는 개인정보 항목 및 수집 방법
                                    <br />
                                    3. 개인정보 처리 및 보유기간
                                    <br />
                                    4. 개인정보 제3자 제공
                                    <br />
                                    5. 개인정보처리 위탁
                                    <br />
                                    6. 개인정보 파기
                                    <br />
                                    7. 정보주체의 권리 · 의무 및 행사방법
                                    <br />
                                    8. 개인정보의 안전성 확보조치
                                    <br />
                                    9. 개인정보 자동수집 장치 설치 · 운영 및 거부
                                    <br />
                                    10. 개인정보 보호책임자 및 담당자
                                    <br />
                                    11. 권익침해 구제 방법
                                    <br />
                                    12. 개인정보 처리방침 변경
                                </p>
                            </section>
                            <section>
                                <h2>제1조 개인정보의 처리 목적</h2>
                                회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적
                                이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 「개인정보 보호법」
                                제18조에 따라 별도의 동의를 받아 필요한 조치를 이행할 예정입니다.
                                <br />
                                <blockquote>
                                    <h2>
                                        1. 회원가입 및 관리
                                        <br />
                                    </h2>
                                    회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리,
                                    제한적 본인확인제 시행에 따른 본인 확인, 서비스 부정이용방지, 만 14세 미만 아동
                                    이용제한, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.
                                    <h2>2. 재화 또는 서비스 제공에 관한 계약 이행 및 요금 정산</h2>
                                    서비스 제공, 계약서·청구서 발송, 콘텐츠 제공 맞춤서비스 제공, 본인인증, 연령인증,
                                    요금결제·정산, 채권추심 목적으로 개인정보를 처리합니다.
                                    <h2>
                                        3. 고객 상담
                                        <br />
                                    </h2>
                                    회원의 서비스 이용문의 및 상담 시 회원의 신원확인, 민원사항 확인, 사실조사를 위한
                                    연락·통지, 처리 결과 통보 목적으로 개인정보를 처리합니다.
                                    <h2>
                                        4. 마케팅 및 광고성 정보 전달에 활용
                                        <br />
                                    </h2>
                                    통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 정보 및
                                    참여기회 제공, 광고성 정보 제공, 접속빈도 파악, 회원의 서비스 이용에 대한 통계
                                    목적으로 개인정보를 처리합니다.
                                    <h2>
                                        5. 제휴 골프장 예약정보 조회
                                        <br />
                                    </h2>
                                    제휴 골프장에 예약된 내역을 조회하고 비대면 서비스(셀프체크인, 동반자 초대 등)를
                                    제공하기 위해 개인정보를 수집하고 메인 페이지, 예약내역 페이지, 스마트라운드 페이지
                                    진입 시 자동 업데이트된 전송요구 내역정보를 전송받습니다.
                                    <br />
                                    <span className="circleNum">①</span> 정보를 보내는 곳<br />
                                    제휴 골프장
                                    <br />
                                    <span className="circleNum">②</span> 전송요구 내역정보
                                    <br />
                                    예약처, 골프장명, 코스명, 라운드 홀수, 티타임 날짜 및 시간, 예약한 그린피(1인) 정보,
                                    총 그린피 결제 예상 금액, 결제방법 등
                                    <br />
                                    <span className="circleNum">③</span> 정보를 받는 곳<br />
                                    골라가(그린잇)
                                    <br />
                                </blockquote>
                            </section>
                            <section>
                                <h2>제2조 처리하는 개인정보 항목 및 수집 방법</h2>
                                <p>회사는 서비스 제공을 위해 다음의 개인정보 항목을 처리하고 있습니다.</p>
                                <br />
                                1. 개인정보 수집 항목
                                <br />
                                <blockquote>
                                    <span className="circleNum">①</span> 회사는 회원가입 및 고객문의, 원활한 서비스
                                    제공을 위해 회원가입 및 서비스 이용 과정에서 아래와 같은 최소한의 개인정보를
                                    수집하고 있습니다.
                                    <br />
                                    가) 회원가입
                                    <br />
                                    i. 필수 : 이름, 휴대전화번호, 생년월일, 성별, 아이디(이메일 주소), 비밀번호,
                                    본인확인정보(CI)
                                    <br />
                                    ii. 선택 : 위치정보, 사진, 주소록 내 저장된 연락처 정보, 카카오 프로필 이미지
                                    <br />
                                    나) 골프 라운드 예약 결제
                                    <br />
                                    i. 필수 : 거래내역
                                    <br />
                                    * 결제 진행 시 개인정보는 PG(결제대행업체)사에서 수집 및 저장하고 있으며, 회사는
                                    PG사에서 제공하는 거래 내역만 제공 받음
                                    <br />
                                    다) 제휴 골프장 예약정보 조회 및 비대면 서비스(셀프체크인, 동반자 초대 등)제공
                                    <br />
                                    - 필수 : 이름, 휴대전화번호
                                    <br />
                                    <br />
                                    <span className="circleNum">②</span> 서비스 이용과정이나 사업처리 과정에서 아래와
                                    같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
                                    <br />
                                    가) 기기정보(OS, 화면사이즈, 디바이스 아이디, 폰 기종, 단말기 모델명)
                                    <br />
                                    나) IP 주소, 쿠키, 서비스 이용 기록, 부정 이용 기록 등의 정보
                                    <br />
                                    <span className="circleNum">③</span> 회사의 맞춤 서비스 이용 또는 이벤트 응모
                                    과정에서 개인정보 추가 수집에 대해 동의한 서비스 이용자에 한해서 아래와 같은 정보가
                                    수집될 수 있습니다. 이 과정에서 수집 관련 내용을 안내하고 별도 동의를 받습니다.
                                    <br />
                                    - 이름, 생년월일, 휴대전화번호, 주소 등<br />
                                </blockquote>
                                2. 개인정보 수집 방법
                                <br />
                                회사가 개인정보를 수집하는 경우에 사전에 이용자에게 해당 사실을 알리고 동의를 구하고
                                있으며, 아래와 같은 방법을 통해 개인정보를 수집합니다.
                                <br />
                                <blockquote>
                                    가) 골프장 예약 서비스 앱 이용을 위한 개인정보 수집
                                    <br />
                                    나) 데이터분석 툴을 통한 수집
                                    <br />
                                    다) 온/오프라인 경품행사 응모를 위한 개인정보 수집
                                    <br />
                                    라) 이벤트 및 고객문의를 위한 개인정보 수집
                                    <br />
                                </blockquote>
                            </section>
                            <section>
                                <h2>제3조 개인정보 처리 및 보유기간</h2>
                                <blockquote>
                                    <p>
                                        1. 회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집
                                        시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다. 다만,
                                        관계 법령의 규정에 따라 보존할 필요성이 있는 경우에는 관계법령에 따라
                                        보존합니다.
                                        <br />
                                        2. 회원의 개인정보 보유 및 이용 기간은 서비스 이용계약 해지(회원탈퇴)하는
                                        기간까지입니다. 다만, 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는
                                        해당 수사·조사 종료 시까지 보유합니다.
                                        <br />
                                        3. 관계 법령에 의한 개인정보 보유 기간은 다음과 같습니다.
                                        <br />
                                    </p>
                                </blockquote>
                                <table cellPadding="0" cellSpacing="0" className="agreeInfoTb">
                                    <colgroup>
                                        <col style={{width: '45%'}} />
                                        <col style={{width: '10%'}} />
                                        <col style={{width: '45%'}} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>보유 항목</th>
                                            <th>보유 기간</th>
                                            <th>관련 법률</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>계약 또는 청약철회 등에 관한 기록</td>
                                            <td>5년</td>
                                            <td rowSpan="2">「전자상거래 등에서 소비자 보호에 관한 법률」</td>
                                        </tr>
                                        <tr>
                                            <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                                            <td>5년</td>
                                        </tr>
                                        <tr>
                                            <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                                            <td>3년</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>서비스 방문 기록</td>
                                            <td>
                                                3개월
                                                <br />
                                            </td>
                                            <td>「통신비밀보호법」</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                                4. 관계 법령에 그 근거가 없더라도, 회사의 중대한 손실을 예방하거나, 범죄 및 소송 등을
                                위해 보관해야 하는 경우 회사 방침에 따라 보관할 수 있습니다. 단, 그 목적을 달성하기 위한
                                최소한의 기간 및 항목만 보유합니다.
                                <br />
                                <table cellPadding="0" cellSpacing="0" className="agreeInfoTb">
                                    <colgroup>
                                        <col style={{width: '45%'}} />
                                        <col style={{width: '10%'}} />
                                        <col style={{width: '45%'}} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>보관 정보</th>
                                            <th>보관 기간</th>
                                            <th>보관 사유</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>부정이용 회원 탈퇴 후 이용자 정보에 관한 기록</td>
                                            <td>6개월</td>
                                            <td rowSpan="2">부정이용 회원의 재발방지</td>
                                        </tr>
                                        <tr>
                                            <td>이용약관에 따라 자격 상실된 회원정보</td>
                                            <td>5년</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                            </section>
                            <section>
                                <h2>제4조 개인정보 제3자 제공</h2>
                                <p>
                                    1. 회사는 회원에게 골프예약 서비스 제공을 위해 필요한 범위 내에서 개인정보 제공에
                                    대한 동의를 구한 후 아래 정보를 제3자에게 제공하고 있습니다.
                                    <br />본 개인정보 제3자 제공에 대한 동의를 거부하실 수 있으며, 이 경우 회원가입은
                                    가능하나 골프예약 서비스 이용이 제한될 수 있습니다.
                                </p>
                                <br />
                                <table cellPadding="0" cellSpacing="0" className="agreeInfoTb">
                                    <colgroup>
                                        <col style={{width: '25%'}} />
                                        <col style={{width: '25%'}} />
                                        <col style={{width: '25%'}} />
                                        <col style={{width: '25%'}} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>제공 받는 자</th>
                                            <th>제공 목적</th>
                                            <th>제공 항목</th>
                                            <th>보유/이용기간</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                제휴골프장
                                                <br />
                                                <Link to="#" onClick={partnersOpen}>
                                                    <span>상세보기</span>
                                                </Link>
                                                {/*<DefaultAlert*/}
                                                {/*    open={open}*/}
                                                {/*    handleOpen={handleOpen}*/}
                                                {/*    handleClose={handleClose}*/}
                                                {/*    title={'제휴골프장 목록\n'}*/}
                                                {/*    contents={*/}
                                                {/*        <ul className="companyList">*/}
                                                {/*            {golfClubPartners?.length*/}
                                                {/*                ? golfClubPartners.map((v, idx) => {*/}
                                                {/*                      return <li key={v.id}>{v.golfClubShortName}</li>;*/}
                                                {/*                  })*/}
                                                {/*                : ''}*/}
                                                {/*        </ul>*/}
                                                {/*    }*/}
                                                {/*/>*/}
                                            </td>
                                            <td>고객 예약 확인 및 안내</td>
                                            <td>예약자명, 휴대전화번호</td>
                                            <td>
                                                회원탈퇴 또는 동의 철회 시까지
                                                <br />
                                                단, 관계 법령이 정한 시점까지 보존
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                                2. 회사는 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에
                                해당하는 경우를 제외하고는 개인정보를 제3자에게 제공하지 않습니다. 다만, 아래의 경우에는
                                예외로 합니다.
                                <br />
                                <blockquote>
                                    가) 회원이 개인정보 제3자 제공에 동의한 경우
                                    <br />
                                    나) 법령 위반에 따른 수사 및 조사 목적으로 법원, 수사기관 및 감독당국의 요구가 있는
                                    경우
                                    <br />
                                    다) 재화 등의 거래에 따른 대금정산을 위해 필요한 경우
                                    <br />
                                    라) 회사 합병 혹은 영업 이동에 따른 개인정보 이관
                                    <br />
                                    마) 그 외 법률 규정 또한 관계 법령에 의해 필요한 경우
                                    <br />
                                </blockquote>
                            </section>
                            <section>
                                <h2>제5조 개인정보 위탁</h2>
                                <p>
                                    1. 회사는 이용자에게 서비스 제공과 계약 이행을 위해 반드시 필요한 업무 중 일부를
                                    외부 업체가 수행하도록 개인정보를 위탁하고 있습니다.
                                </p>
                                <table cellPadding="0" cellSpacing="0" className="agreeInfoTb">
                                    <colgroup>
                                        <col style={{width: '30%'}} />
                                        <col style={{width: '40%'}} />
                                        <col style={{width: '30%'}} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>제공받은 업체</th>
                                            <th>이용 목적</th>
                                            <th>취급 개인정보</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="alignC">SCI 평가정보</td>
                                            <td>휴대폰 본인인증 서비스</td>
                                            <td rowSpan="6">
                                                회원탈퇴 또는
                                                <br />
                                                위탁 계약 종료시까지
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="alignC">엠비아이솔루션(주)</td>
                                            <td>카카오 비즈메시지 발송 서비스</td>
                                        </tr>
                                        <tr>
                                            <td className="alignC">채널코퍼레이션</td>
                                            <td>고객상담 처리</td>
                                        </tr>
                                        <tr>
                                            <td className="alignC">Amazon Web Service Inc</td>
                                            <td>데이터 처리 및 보관</td>
                                        </tr>
                                        <tr>
                                            <td className="alignC">AB180 Inc.</td>
                                            <td>앱 이용정보 수집, 광고 성과 측정</td>
                                        </tr>
                                        <tr>
                                            <td className="alignC">(주)헥토파이낸셜</td>
                                            <td>
                                                휴대폰, 신용카드, 계좌이체, 간편결제, 무통장 입금(가상계좌,
                                                실시간계좌이체 등) 등을 통한 골프 라운드 예약 결제 및 온라인 주문 결제
                                                처리
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                                <p>
                                    2. 회사는 위탁받은 업체가 관련 법령을 위반하지 않도록 관리·감독합니다.
                                    <br />
                                    3. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통해
                                    공개하겠습니다.
                                    <br />
                                    4. 고객 정보 일부는 서비스 향상을 위한 통계분석 목적으로 국외에 위치하고 있는 서버에
                                    이전 및 저장될 수 있습니다.
                                </p>
                                <table cellPadding="0" cellSpacing="0" className="agreeInfoTb">
                                    <colgroup>
                                        <col style={{width: '15%'}} />
                                        <col style={{width: '15%'}} />
                                        <col style={{width: '15%'}} />
                                        <col style={{width: '20%'}} />
                                        <col style={{width: '15%'}} />
                                        <col style={{width: '20%'}} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>이전 받는 자</th>
                                            <th>이용 목적</th>
                                            <th>이전 국가</th>
                                            <th>이전 일시 및 방법</th>
                                            <th>이전 항목</th>
                                            <th>보유 및 이용기간</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="alignC">Amplitude Inc.</td>
                                            <td>통계 분석 및 서비스 향상 목적</td>
                                            <td>미국</td>
                                            <td>서비스 이용 시점에 네트워크를 통한 전송</td>
                                            <td>개인정보 수집 항목에 있는 개인정보</td>
                                            <td>회원탈퇴 또는 위탁 계약 종료 시까지</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </section>
                            <section>
                                <h2>제6조 개인정보 파기</h2>
                                <p>
                                    1. 회사는 회원의 탈퇴 또는 영구이용정지 시 회원의 개인정보를 지체없이 파기합니다.
                                    <br />
                                    2. 다른 법령 또는 회사의 내부 방침(서비스 부정 이용 방지 등)에 따라 개인정보를 계속
                                    보존하여야 하는 경우에는 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나
                                    보관장소를 달리하여 보존합니다. 이 때 법령에 의한 경우를 제외하고 다른 목적으로
                                    이용하지 않습니다.
                                    <br />
                                    3. 개인정보 파기의 절차 및 방법은 다음과 같습니다.
                                    <blockquote>
                                        가) 파기절차
                                        <br />
                                        i. 회사는 회원의 개인정보 이용목적 달성 및 서비스 해지 요청이 있을 경우 지체없이
                                        파기합니다.
                                        <br />
                                        ii. 회사는 파기 사유가 발생한 개인정보는 개인정보 보호책임자의 승인을 받아
                                        개인정보를 파기합니다.
                                        <br />
                                        나) 파기방법
                                        <br />
                                        i. 회사는 전자적 파일 형태로 기록∙저장된 개인정보는 기록을 재생할 수 없도록
                                        기술적 방법을 사용하여 파기하며, 종이 문서에 기록∙저장된 개인정보는 분쇄기로
                                        분쇄하거나 소각하여 파기합니다.
                                    </blockquote>
                                    4. 미이용자의 개인정보 파기 등에 관한 조치는 다음과 같습니다.
                                    <blockquote>
                                        가) 회사는 ’개인정보 유효기간제’ 에 따라 1년간 서비스 로그인 기록이 없는 회원은
                                        휴면계정으로 전환하고 개인정보를 별도로 분리 보관합니다. 분리 보관된 개인정보는
                                        4년간 보관 후 지체없이 파기합니다.
                                        <br />
                                        나) 분리 보관 대상자에게는 분리 보관 처리일을 기준으로 최소 30일 이전에 이메일,
                                        SMS 등 이용자에게 통지 가능한 방법으로 알립니다.
                                        <br />
                                        다) 휴면계정으로 전환을 원하지 않는 경우, 휴면계정 전환 전 서비스 로그인을 해야
                                        합니다. 또한 휴면계정으로 전환되었더라도 서비스 로그인하여 휴면계정 해제에 동의
                                        시, 휴면계정이 복원되어 정상적인 서비스를 이용할 수 있습니다.
                                        <br />
                                    </blockquote>
                                    5. 회사는 서비스 부정 이용 및 영구이용정지 대상자의 재가입 방지를 위해 해당 회원의
                                    식별정보를 별도 분리 보관할 수 있습니다.
                                </p>
                            </section>
                            <section>
                                <h2>제7조 정보주체의 권리·의무 및 행사방법</h2>
                                <p>
                                    1. 정보주체는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를
                                    행사할 수 있습니다.
                                    <br />
                                    2. 제1항에 따른 권리 행사는 회사에 대해 개인정보보호법 시행령 제41조 제1항에 따라
                                    서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체없이
                                    조치합니다.
                                    <br />
                                    3. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을
                                    통하여 하실 수 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지
                                    제11호 서식에 따른 위임장을 제출하셔야 합니다.
                                    <br />
                                    4. 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제4항, 제37조 제2항에
                                    의하여 정보주체의 권리가 제한될 수 있습니다.
                                    <br />
                                    5. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어
                                    있는 경우에는 그 삭제를 요구할 수 없습니다.
                                    <br />
                                    6. 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시
                                    열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
                                    <br />
                                </p>
                            </section>
                            <section>
                                <h2>제8조 개인정보의 안전성 확보조치</h2>
                                <p>
                                    회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
                                    <br />
                                    <br />
                                    1. 관리적 조치
                                    <br />
                                    <blockquote>
                                        가) 개인정보의 안전한 처리를 위한 내부관리계획의 수립
                                        <br />
                                        나) 내부관리계획에 따른 정기적인 직원 교육 및 자체 점검의 실시
                                        <br />
                                    </blockquote>
                                    2. 기술적 조치
                                    <br />
                                    <blockquote>
                                        가) 개인정보시스템에 대한 접근 권한 관리
                                        <br />
                                        나) 고유식별 정보 암호화
                                        <br />
                                        다) 침입차단 및 침입탐지시스템을 운영
                                        <br />
                                        라) 개인정보처리시스템 또는 컴퓨터에 보안 프로그램 설치
                                        <br />
                                    </blockquote>
                                    3. 물리적 조치
                                    <br />- 정보유출 등을 방지하기 위한 전산실 출입 통제
                                </p>
                            </section>
                            <section>
                                <h2>제9조 개인정보 자동수집 장치 설치·운영 및 거부</h2>
                                <p>
                                    1. 회사는 보다 빠르고 편리한 서비스 이용 및 맞춤형 서비스 제공을 위해 회원의 정보를
                                    저장하고 수시로 불러오는 ‘쿠키’를 사용합니다.
                                    <br />
                                    2. 회원이 웹사이트에 방문할 경우 웹 사이트 서버는 이용자의 디바이스에 저장되어 있는
                                    쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화 서비스를 제공합니다.
                                    <br />
                                    3. 쿠키의 사용 목적은 회원이 방문한 다양한 서비스와 웹 사이트에 대한 방문 및
                                    이용형태, 인기 검색어, 보안접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을
                                    위해 활용됩니다.
                                    <br />
                                    4. 회원은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹 브라우저에서
                                    옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나,
                                    아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키의 저장을 거부할 경우에는
                                    로그인이 필요한 일부 서비스는 이용에 어려움이 있을 수 있습니다.
                                    <br />
                                    5. 쿠키 설치 허용 여부를 지정하는 방법은 아래와 같습니다.
                                    <br />
                                    <blockquote>
                                        가) Internet Explorer
                                        <br />
                                        - 웹 브라우저 상단의 도구 메뉴 > 인터넷 옵션 > 개인정보 > 설정
                                        <br />
                                        나) Chrome
                                        <br />
                                        - 웹 브라우저 우측의 설정 메뉴 > 화면 하단의 고급 설정 표시 > 개인정보의 콘텐츠
                                        설정 버튼 > 쿠키
                                        <br />
                                    </blockquote>
                                </p>
                            </section>
                            <section>
                                <h2>제10조 개인정보 보호책임자 및 담당자</h2>
                                <p>
                                    회사는 개인정보를 보호하고 개인정보와 관련한 사항을 처리하기 위해 개인정보 관리담당
                                    부서 또는 개인정보 보호책임자를 두고 있습니다. 개인정보와 관련한 문의 사항이 있으면
                                    아래로 연락하여 주시기 바랍니다.
                                </p>
                                <table cellPadding="0" cellSpacing="0" className="agreeInfoTb">
                                    <colgroup>
                                        <col style={{width: '30%'}} />
                                        <col style={{width: '40%'}} />
                                        <col style={{width: '30%'}} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>구분</th>
                                            <th>담당</th>
                                            <th>연락처</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>개인정보 관리 부서</td>
                                            <td>프로덕트 개발본부</td>
                                            <td rowSpan="2">1600-1552</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                개인정보보호
                                                <br />
                                                최고책임자
                                            </td>
                                            <td>박종하</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                            </section>
                            <section>
                                <h2>제11조 권익침해 구제 방법</h2>
                                <p>
                                    정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수
                                    있습니다.
                                    <br />
                                </p>
                                <p>
                                    <span className="circleNum">1.</span> 개인정보침해 신고센터 (한국인터넷진흥원 운영)
                                    <br />
                                    · (국번없이)118
                                    <br />·{' '}
                                    <Link href="https://privacy.kisa.or.kr" target="_blank">
                                        https://privacy.kisa.or.kr
                                    </Link>
                                    <br />
                                    <br />
                                    <span className="circleNum">2.</span> 개인정보 분쟁조정위원회
                                    <br />
                                    · (국번없이)1833-6972
                                    <br />·{' '}
                                    <Link href="https://www.kopico.go.kr" target="_blank">
                                        https://www.kopico.go.kr
                                    </Link>
                                    <br />
                                    <br />
                                    <span className="circleNum">3.</span> 대검찰청 사이버수사단
                                    <br />
                                    · (국번없이)1301
                                    <br />·{' '}
                                    <Link href="https://www.spo.go.kr" target="_blank">
                                        https://www.spo.go.kr
                                    </Link>
                                    <br />
                                    <br />
                                    <span className="circleNum">4.</span> 경찰청 사이버수사국
                                    <br />
                                    · (국번없이)182
                                    <br />·{' '}
                                    <Link href="http://cyberbureau.police.go.kr" target="_blank">
                                        http://cyberbureau.police.go.kr
                                    </Link>
                                </p>
                            </section>
                            <section>
                                <h2>제12조 개인정보처리방침 변경</h2>
                                <p>
                                    회사는 정부의 정책 또는 회사의 필요에 의해 개인정보처리방침을 수정할 수 있습니다.
                                    개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경 내용의 추가, 삭제
                                    및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지합니다.
                                    다만, 개인정보의 수집 및 이용, 제3자 제공 등과 같이 이용자 권리의 중요한 변경이 있을
                                    경우에는 최소 30일전에 고지합니다.
                                    <br />
                                    <br />이 개인정보 처리방침은 2023년 05월 02일부터 적용됩니다.
                                </p>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PrivacyPolicy;
